/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, { FC } from 'react';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  extraClassNames?: string;
  buttonType?: ButtonType;
  shadowType?: ShadowType;
};

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Quaternary = 'quaternary',
}

export enum ShadowType {
  Red = 'drop-shadow-[0_4px_4px_rgba(233,5,23,0.25)]',
  Black = 'drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]',
  None = '',
}

export const VdzButton: FC<Props> = ({
  children,
  extraClassNames,
  shadowType = ShadowType.None,
  buttonType = ButtonType.Primary,
  onClick,
  ...rest
}) => {
  return (
    <button
      className={`button ${buttonType} ${shadowType} ${extraClassNames}`}
      data-testid="button"
      onClick={onClick}
      {...rest}
    >
      <>{children}</>
    </button>
  );
};
