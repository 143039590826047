/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, { FC, lazy } from 'react';

import {
  useDocumentTitle,
} from '../modules/shared/hooks/use-document-title/use-document-title.hook';
import { VdzPage } from './page';

const VdzContent = lazy(
  () =>
    import(
      /*webpackChunkName: "calculator" */ '../modules/content/components/content.component'
    )
);

export const CalculatorPage: FC = () => {
  useDocumentTitle('footer.calculator.title');

  return (
    <VdzPage>
      <VdzContent />
    </VdzPage>
  );
};
