/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import { CountriesDataType } from './locale-data.types';

export type GlobalConfigDataType = {
  vdzVersion: string;
  environmentName: string;
  displayEntyvioLogo: boolean;
  imageResourceUrl: string;
  countryConfiguration: CountriesDataType;
  vdzMfgAddress: string;
  verboseApi?: boolean;
};

export type LabellingFieldMap = {
  [key in LabellingFieldId]: LabellingFieldDataType;
};

export type LabellingFieldDataType = {
  enabled: boolean;
  id: LabellingFieldId;
  value: string;
};

export enum LabellingFieldId {
  VDZ_CE_ICON = 'VDZ_CE_ICON',
  VDZ_DISTRIBUTOR_ADDRESS = 'VDZ_DISTRIBUTOR_ADDRESS',
  VDZ_DISTRIBUTOR_ICON = 'VDZ_DISTRIBUTOR_ICON',
  VDZ_EC_REP_ADDRESS = 'VDZ_EC_REP_ADDRESS',
  VDZ_EC_REP_ICON = 'VDZ_EC_REP_ICON',
  VDZ_IMPORTER_ADDRESS = 'VDZ_IMPORTER_ADDRESS',
  VDZ_IMPORTER_ICON = 'VDZ_IMPORTER_ICON',
  VDZ_MD_ICON = 'VDZ_MD_ICON',
  VDZ_MFG_ICON = 'VDZ_MFG_ICON',
  VDZ_NOTIFIED_BODY_ID = 'VDZ_NOTIFIED_BODY_ID',
  VDZ_RELEASE_DATE = 'VDZ_RELEASE_DATE',
  VDZ_UDI_ICON = 'VDZ_UDI_ICON',
  VDZ_UDI_INFORMATION = 'VDZ_UDI_INFORMATION',
}

export enum Environment {
  DEMO = 'DEMO',
  DEV = 'DEV',
  PERF = 'PERF',
  PRD = 'PRD',
  VAL = 'VAL',
  VER = 'VER',
}
