/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import axios from 'axios';

import { getBaseUrl } from '../../domain/browser.domain';
import { GlobalConfigDataType } from '../../domain/global-config-data.types';

export const getGlobalConfigData = () => {
  return axios
    .get<GlobalConfigDataType>(`${getBaseUrl()}/config/global-config.json`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      throw Error('Error fetching config data');
    });
};
