/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import { useContext } from 'react';

import { GlobalConfigContext } from '../../contexts/global-config/global-config.context';

export const useGlobalConfig = () => {
  const context = useContext(GlobalConfigContext);

  if (!context) {
    throw new Error(
      'useGlobalConfig must be used inside a GlobalConfigContextProvider'
    );
  }

  return context;
};
