/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { FC } from 'react';

type Props = {
  expanded: boolean;
  onClick: () => void;
};

export const VdzHamburgerComponent: FC<Props> = ({ expanded, onClick }) => {
  return (
    <button
      className="flex flex-col h-6 w-6 rounded justify-center items-center group"
      onClick={onClick}
    >
      <div
        className={`${lineClassName} ${
          expanded ? 'rotate-45 translate-y-2' : ''
        }`}
      />

      <div className={`${lineClassName} ${expanded ? 'opacity-0' : ''}`} />

      <div
        className={`${lineClassName} mb-0 ${
          expanded ? '-rotate-45 -translate-y-2' : ''
        }`}
      />
    </button>
  );
};

const lineClassName = `h-1 w-6 mb-1 rounded-full bg-white transition ease transform duration-300`;
