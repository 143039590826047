/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { AnchorHTMLAttributes, FC } from 'react';

export const VdzAnchor: FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  className = 'underline text-brand-blue',
  ...props
}) => (
  <a {...props} className={className} rel="noreferrer" target="_blank">
    {children}
  </a>
);
