/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

type Props = HTMLAttributes<HTMLDivElement> & {
  html?: string | null;
  literal?: string;
};

export const VdzHTML: FC<Props> = ({ html, literal, ...props }) => {
  const { t } = useTranslation();

  const content = (literal ? t(literal) : html) || '';

  return <div {...props} dangerouslySetInnerHTML={{ __html: content }}></div>;
};
