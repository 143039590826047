/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import { StorageKeys } from '../../../../domain/storage/storage.constants';
import { useLocalStorage } from '../use-local-storage/use-local-storage.hook';

export const useDocCheckAccessToken = () => {
  const { getStorageValue, setStorageValue, removeStorageKey } =
    useLocalStorage();

  const docCheckAccessToken = getStorageValue(StorageKeys.DocCheckAccessToken);

  const setDocCheckAccessToken = (locale: string) => {
    setStorageValue(StorageKeys.DocCheckAccessToken, locale);
  };

  const clearDocCheckAccessToken = () => {
    removeStorageKey(StorageKeys.DocCheckAccessToken);
  };

  return {
    docCheckAccessToken,
    setDocCheckAccessToken,
    clearDocCheckAccessToken,
  };
};
