/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import axios from 'axios';

import { DocCheckSession, DocCheckTokenValidation } from './authentication.api.types';

export const getDocCheckAccessToken = (
  authorizationCode: string,
  country: string,
  clientId: number
) => {
  return axios
    .post<DocCheckSession>('/api/authorization/accessToken', {
      authorizationCode,
      clientId,
      country,
    })
    .then((res) => res.data)
    .catch(() => {
      throw Error('Error getting DocCheck access token');
    });
};

export const validateDocCheckAccessToken = (accessToken: string) => {
  return axios
    .post<DocCheckTokenValidation>('/api/authorization/validate', {
      access_token: accessToken,
    })
    .then((res) => res.data)
    .catch(() => {
      throw Error('Error validating DocCheck access token');
    });
};
