/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, { FC, lazy } from 'react';

import { LoginType } from '../domain/authentication/authentication.types';
import { VdzPage } from './page';

const VdzLoginContent = lazy(
  () =>
    import(
      /*webpackChunkName: "login" */ '../modules/authentication/login/login.component'
    )
);

type Props = {
  type: LoginType;
};

export const LoginPage: FC<Props> = ({ type }: Props) => (
  <VdzPage name="login" className="bg-brand-gray-light">
    <VdzLoginContent type={type} />
  </VdzPage>
);
