/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import { useCookies } from 'react-cookie';

import { getCookieOptions } from '../../../../domain/cookies/cookies.domain';
import {
  getCountryFromLocale, getLanguageFromLocale,
} from '../../../../domain/global-config.domain';
import { LOCALE_COOKIE } from '../../../authentication/authentication.constants';

export const useLocale = () => {
  const [cookies, setCookie, removeCookie] = useCookies([LOCALE_COOKIE]);

  const locale = cookies[LOCALE_COOKIE];

  const country = getCountryFromLocale(locale) || '';

  const language = getLanguageFromLocale(locale) || '';

  const setLocale = (locale: string) => {
    return setCookie(LOCALE_COOKIE, locale, getCookieOptions());
  };

  const clearLocale = () => {
    removeCookie(LOCALE_COOKIE, getCookieOptions());
  };

  return {
    country,
    language,
    locale,
    clearLocale,
    setLocale,
  };
};
