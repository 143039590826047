/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentYear } from '../../../domain/dates/dates.domain';
import { ResetLocaleComponent } from '../../locale-picker/components/reset-locale.component';
import { VdzFooterCountryLogo } from './footer-country-logo.component';
import { VdzFooterLinks } from './footer-links.component';
import { VdzFooterLogo } from './footer-logo.component';

type Props = {
  isAuthPage?: boolean;
};

export const VdzFooter: FC<Props> = ({ isAuthPage }) => {
  const { t } = useTranslation();

  return (
    <footer data-testid="app-footer" className="w-full print:hidden">
      <>
        {isAuthPage ? (
          <div className="flex flex-row justify-between py-5 px-5 sm:px-11">
            <VdzFooterLogo />
            <ResetLocaleComponent />
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row justify-between py-5 px-5 sm:px-11">
            <div className="hidden lg:flex w-[8rem] lg:w-[12rem] lg:ms-3" />

            <div className="flex-1 items-center">
              <VdzFooterLinks />

              <div className="text-xs text-neutral-400 mt-2 text-center whitespace-pre-line">
                {t('footer.copyright', { year: getCurrentYear() })}
              </div>
            </div>

            <div className="w-full lg:w-[12rem] flex flex-row justify-center lg:justify-end mt-3 lg:mt-0 lg:me-3">
              <VdzFooterCountryLogo />
            </div>
          </div>
        )}
      </>
    </footer>
  );
};
