/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, { FC, lazy } from 'react';

import { VdzPage } from './page';

const VdzSignUpContent = lazy(
  () =>
    import(
      /*webpackChunkName: "sign-up" */ '../modules/authentication/sign-up/sign-up.component'
    )
);

export const SignUpPage: FC = () => (
  <VdzPage name="signup" className="bg-brand-gray-light">
    <VdzSignUpContent />
  </VdzPage>
);
