/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import './notification-banner.component.scss';

import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { getCurrentDate, toDate } from '../../../domain/dates/dates.domain';
import { getCountryConfiguration } from '../../../domain/global-config.domain';
import { IconImage } from '../../images/images.constants';
import { getImageUrl } from '../../images/images.domain';
import { VdzHTML } from '../../shared/components/html.component';
import {
  useBannerDismissDate,
} from '../../shared/hooks/use-banner-dismiss-date/use-banner-dismiss-date.hook';
import { useGlobalConfig } from '../../shared/hooks/use-global-config/use-global-config.hook';
import { useLocale } from '../../shared/hooks/use-locale/use-locale.hook';
import { pagesWithoutBanner } from '../banner.constants';

export const VdzNotificationBanner: FC = () => {
  const { globalConfig } = useGlobalConfig();
  const { pathname } = useLocation();
  const { country, language } = useLocale();
  const { dismissDate, setDismissDate } = useBannerDismissDate();

  const bannerConfig = getCountryConfiguration(
    country,
    globalConfig.countryConfiguration
  )?.notificationBanner;
  const bannerContent = bannerConfig?.content[language];

  const isVisible = () => {
    const bannerDate = toDate(bannerConfig?.updateDate);

    return (
      bannerConfig?.enabled &&
      bannerDate &&
      (!dismissDate || bannerDate > dismissDate) &&
      !pagesWithoutBanner.includes(pathname)
    );
  };

  const dismiss = () => {
    setDismissDate(getCurrentDate());
  };

  return isVisible() && bannerContent ? (
    <div className="notification-banner bg-brand-red text-white flex items-center print:hidden">
      <VdzHTML
        className={`${commonClassnames} lg:hidden`}
        html={bannerContent.mobile || bannerContent.desktop}
      />

      <VdzHTML
        className={`${commonClassnames} hidden lg:block`}
        html={bannerContent.desktop}
      />

      <img
        alt="close-icon"
        className="h-[16px] m-3 cursor-pointer"
        src={getImageUrl(IconImage.CloseWhite)}
        onClick={dismiss}
      />
    </div>
  ) : null;
};

const commonClassnames = `flex-1 text-center p-2`;
