/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, { FC, lazy } from 'react';

import {
  useDocumentTitle,
} from '../modules/shared/hooks/use-document-title/use-document-title.hook';
import { VdzPage } from './page';

const VdzResourceContent = lazy(
  () =>
    import(
      /*webpackChunkName: "resources" */ '../modules/resources/components/resource.component'
    )
);

export const ResourcesPage: FC = () => {
  useDocumentTitle('footer.resources.title');

  return (
    <VdzPage>
      <VdzResourceContent />
    </VdzPage>
  );
};
