/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import { useContext } from 'react';

import { EnvConfigContext } from '../../contexts/env-config/env-config.context';

export const useEnvConfig = () => {
  const context = useContext(EnvConfigContext);

  if (!context) {
    throw new Error(
      'useEnvConfig must be used inside a EnvConfigContextProvider'
    );
  }

  return context;
};
