/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import { dateToString, toDate } from '../../../../domain/dates/dates.domain';
import { StorageKeys } from '../../../../domain/storage/storage.constants';
import { removeStorageKey } from '../../../../domain/storage/storage.domain';
import { useLocalStorage } from '../use-local-storage/use-local-storage.hook';

export const useSession = () => {
  const { getStorageValue, setStorageValue } = useLocalStorage();

  const getExpiration = () => {
    const value = getStorageValue(StorageKeys.SessionExpiration);
    return value ? toDate(value) : undefined;
  };
  const getTimestamp = () => {
    const value = getStorageValue(StorageKeys.SessionTimestamp);
    return value ? toDate(value) : undefined;
  };

  const setExpiration = (date: Date) => {
    setStorageValue(StorageKeys.SessionExpiration, dateToString(date));
  };
  const setTimestamp = (date: Date) => {
    setStorageValue(StorageKeys.SessionTimestamp, dateToString(date));
  };

  const removeExpiration = () => {
    removeStorageKey(StorageKeys.SessionExpiration);
  };
  const removeTimestamp = () => {
    removeStorageKey(StorageKeys.SessionTimestamp);
  };

  return {
    getExpiration,
    getTimestamp,
    removeExpirationDate: removeExpiration,
    removeTimestamp,
    setExpirationDate: setExpiration,
    setTimestamp,
  };
};
