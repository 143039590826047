/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import './App.scss';

import React, { Suspense, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import {
  getAuthRequiredNavigateTo, getOidcConfig,
} from './domain/authentication/authentication.domain';
import { LoginType } from './domain/authentication/authentication.types';
import { getBaseUrl, isSafari } from './domain/browser.domain';
import { getCountryDatacenterUrl } from './domain/global-config.domain';
import { setTrackedCustomUrl, setTrackedDocumentTitle } from './domain/tracking/tracking.domain';
import Authenticated from './modules/authentication/login/authenticated.component';
import { VdzDemoBanner } from './modules/banners/demo-banner/demo-banner.component';
import {
  VdzNotificationBanner,
} from './modules/banners/notification-banner/notification-banner.component';
import { VdzHeader } from './modules/header/components/header.component';
import { VdzLocalePicker } from './modules/locale-picker/components/locale-picker.component';
import {
  CALCULATOR_PAGE, CONTACT_US_PAGE, COOKIE_POLICY_PAGE, DOC_CHECK_LOGIN_PAGE, LOGIN_PAGE,
  PRESCRIBING_INFORMATION_PAGE, RESOURCES_PAGE, SIGN_UP_PAGE, TAKEDA_ID_LOGIN_PAGE,
} from './modules/navigation/navigation.constants';
import { VdzSpinner } from './modules/shared/components/spinner.component';
import {
  useDocumentTitle,
} from './modules/shared/hooks/use-document-title/use-document-title.hook';
import { useEnvConfig } from './modules/shared/hooks/use-env-config/use-env-config.hook';
import { useGlobalConfig } from './modules/shared/hooks/use-global-config/use-global-config.hook';
import { useLocale } from './modules/shared/hooks/use-locale/use-locale.hook';
import { CalculatorPage } from './pages/calculator.page';
import { ContactUsAboutPage } from './pages/contact-us-about.page';
import { CookiePolicyPage } from './pages/cookie-policy.page';
import { LoginSelectionPage } from './pages/login-selection/login-selection.page';
import { LoginPage } from './pages/login.page';
import { PrescribingInformationPage } from './pages/prescribing-information.page';
import { ResourcesPage } from './pages/resources.page';
import { SignUpPage } from './pages/signup.page';
import { getEnvConfig } from './services/env-config/env-config.service';
import { getGlobalConfigData } from './services/global-config/global-config.service';
import { i18nService } from './services/i18n/i18n.service';

i18nService.init();

const App = () => {
  const navigate = useNavigate();
  const timeout = useRef<NodeJS.Timeout>();
  const oktaAuth = useRef<OktaAuth>();
  const { pathname } = useLocation();
  const { globalConfig, setGlobalConfig } = useGlobalConfig();
  const { setEnvConfig } = useEnvConfig();
  const { country, language, locale } = useLocale();
  const { i18n } = useTranslation();

  const hasLocale = !!locale;

  useDocumentTitle('header.title');

  useEffect(() => {
    if (isSafari()) {
      document.getElementsByTagName('html')[0].classList.add('safari');
    }

    if (hasLocale) {
      document.documentElement.lang = language;
      window.OneTrust?.changeLanguage();
    }

    if (!hasLocale && pathname !== LOGIN_PAGE) {
      navigate(LOGIN_PAGE);
    }

    const loadConfigData = async () => {
      const globalConfig = await getGlobalConfigData();
      setGlobalConfig(globalConfig);

      const envConfig = await getEnvConfig();
      setEnvConfig(envConfig);

      oktaAuth.current = new OktaAuth(getOidcConfig(envConfig));
    };

    loadConfigData();
  }, []);

  useEffect(() => {
    const baseUrl = getBaseUrl();
    const datacenterUrl = country
      ? getCountryDatacenterUrl(country, globalConfig.countryConfiguration)
      : undefined;
    if (datacenterUrl && !baseUrl.startsWith(datacenterUrl)) {
      const pathname = window.location.pathname;
      const redirectUrl = `${datacenterUrl}${pathname}`;
      console.log(`Redirecting to :${redirectUrl}`);
      window.location.replace(redirectUrl);
    }
  }, [country, globalConfig]);

  useEffect(() => {
    if (locale && i18n.language !== locale) {
      i18nService.changeLocale(locale);
    }
  }, [locale, i18n.language]);

  useEffect(() => {
    setTrackedCustomUrl();
    setTrackedDocumentTitle();
  }, [window.location.hash]);

  const restoreOriginalUri = async (_oktaAuth: OktaAuth) => {
    navigate(window.location.origin, {
      replace: true,
    });
  };

  const customAuthHandler = () => {
    clearTimeout(timeout.current);

    navigate(getAuthRequiredNavigateTo(oktaAuth.current));
  };

  return oktaAuth.current ? (
    <Security
      oktaAuth={oktaAuth.current}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <div dir={i18n.dir()} className="app relative print:min-h-[100vh]">
        <Suspense fallback={<VdzSpinner />}>
          {!hasLocale && <VdzLocalePicker />}
          <VdzNotificationBanner />
          <VdzDemoBanner />
          <VdzHeader />
          <Routes>
            <Route
              path={CALCULATOR_PAGE}
              element={
                <Authenticated
                  success={<CalculatorPage />}
                  loading={<VdzSpinner />}
                />
              }
            />
            <Route
              path={RESOURCES_PAGE}
              element={
                <Authenticated
                  success={<ResourcesPage />}
                  loading={<VdzSpinner />}
                />
              }
            />
            <Route
              path={CONTACT_US_PAGE}
              element={
                <Authenticated
                  success={<ContactUsAboutPage />}
                  loading={<VdzSpinner />}
                />
              }
            />
            <Route
              path={COOKIE_POLICY_PAGE}
              element={
                <Authenticated
                  success={<CookiePolicyPage />}
                  loading={<VdzSpinner />}
                />
              }
            />
            <Route
              path={PRESCRIBING_INFORMATION_PAGE}
              element={
                <Authenticated
                  success={<PrescribingInformationPage />}
                  loading={<VdzSpinner />}
                />
              }
            />
            <Route path={SIGN_UP_PAGE} element={<SignUpPage />} />
            <Route path={LOGIN_PAGE} element={<LoginSelectionPage />} />
            <Route
              path={TAKEDA_ID_LOGIN_PAGE}
              element={<LoginPage type={LoginType.TakedaId} />}
            />
            <Route
              path={DOC_CHECK_LOGIN_PAGE}
              element={<LoginPage type={LoginType.DocCheck} />}
            />
          </Routes>
        </Suspense>
      </div>
    </Security>
  ) : (
    <Suspense />
  );
};

export default App;
