/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { FC, lazy } from 'react';

import {
  useDocumentTitle,
} from '../modules/shared/hooks/use-document-title/use-document-title.hook';
import { VdzPage } from './page';

const VdzCookiePolicy = lazy(
  () =>
    import(
      /*webpackChunkName: "cookie-policy" */ '../modules/cookie-policy/components/cookie-policy.component'
    )
);

export const CookiePolicyPage: FC = () => {
  useDocumentTitle('footer.cookie_policy.title');

  return (
    <VdzPage>
      <VdzCookiePolicy />
    </VdzPage>
  );
};
