/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import { t } from 'i18next';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { baseline } from '../../../services/i18n/i18n.constants';
import { i18nService } from '../../../services/i18n/i18n.service';
import { IconImage } from '../../images/images.constants';
import { getImageUrl } from '../../images/images.domain';
import { LOGIN_PAGE } from '../../navigation/navigation.constants';
import { useLocale } from '../../shared/hooks/use-locale/use-locale.hook';
import {
  useTermsAcceptanceDate,
} from '../../shared/hooks/use-terms-acceptance-date/use-terms-acceptance-date.hook';

type Props = React.HTMLAttributes<HTMLElement>;

export const ResetLocaleComponent: FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const { clearAcceptanceDate } = useTermsAcceptanceDate();
  const { country, language, locale, clearLocale } = useLocale();

  const resetCountryAndLocale = () => {
    navigate(LOGIN_PAGE);
    clearAcceptanceDate();
    clearLocale();
    i18nService.changeLocale(baseline);
  };

  return (
    <div className={className} onClick={resetCountryAndLocale}>
      {locale && (
        <div className="h-full text-end cursor-pointer flex flex-column justify-end items-center gap-2">
          <img
            src={getImageUrl(IconImage.Globe)}
            className="w-6"
            alt="locale-icon"
          />
          {t(`locale_picker.locales.${language}`)} (
          {t(`locale_picker.countries.${country}`)})
        </div>
      )}
    </div>
  );
};
