/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import { useContext } from 'react';

import { LocalStorageContext } from '../../contexts/local-storage/local-storage.context';

export const useLocalStorage = () => {
  const context = useContext(LocalStorageContext);

  if (!context) {
    throw new Error(
      'useLocalStorage must be used inside a LocalStorageContextProvider'
    );
  }

  return context;
};
