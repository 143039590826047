/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { WithClassName } from '../shared.types';
import { VdzButton } from './button.component';
import { VdzHTML } from './html.component';

type Props = WithClassName &
  PropsWithChildren<{
    buttonText: string;
    backdropClose?: boolean;
    containerClassName?: string;
    isVisible: boolean;
    html?: string;
    text?: string;
    title: string;
    onClose: () => void;
  }>;
export const VdzModal: FC<Props> = ({
  buttonText,
  backdropClose = false,
  children,
  className,
  containerClassName,
  isVisible,
  html,
  text,
  title,
  onClose,
}) => {
  const { t } = useTranslation();

  const _onClose = () => {
    backdropClose && onClose();
  };

  if (!isVisible) return null;
  return (
    <div
      className={`${className} absolute w-full h-full top-0 left-0 z-40 backdrop-filter backdrop-blur backdrop-brightness-50`}
      onClick={_onClose}
    >
      <div
        className={`${containerClassName} m-5 lg:mx-64 lg:my-16 p-6 sm:p-8 bg-white text-black border rounded-3xl`}
      >
        <div className="font-semibold mb-4 text-center text-xl">{t(title)}</div>
        <div className="mb-8">
          {children || <VdzHTML literal={text} html={html} />}
        </div>
        <div className="flex flex-row justify-center">
          <VdzButton onClick={onClose}>{t(buttonText)}</VdzButton>
        </div>
      </div>
    </div>
  );
};
