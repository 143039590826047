/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

export enum StorageKeys {
  BannerDismissDate = 'vdz.banner.dismiss.date',
  ChosenLocale = 'vdz.locale.chosen.locale',
  DocCheckAccessToken = 'vdz.doc.check.access.token',
  SessionTimestamp = 'vdz.session.timestamp',
  SessionExpiration = 'vdz.session.expiration',
  TermsAcceptanceDate = 'vdz.terms.acceptance.date',
}
