/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';

import { GlobalConfigDataType } from '../../../../domain/global-config-data.types';

type GlobalConfigContextType = {
  globalConfig: GlobalConfigDataType;
  setGlobalConfig: Dispatch<SetStateAction<GlobalConfigDataType>>;
};

export const GlobalConfigContext = createContext<
  GlobalConfigContextType | undefined
>(undefined);

export const GlobalConfigContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [globalConfig, setGlobalConfig] = useState<GlobalConfigDataType>(
    {} as GlobalConfigDataType
  );

  return (
    <GlobalConfigContext.Provider
      value={{
        globalConfig,
        setGlobalConfig,
      }}
    >
      {children}
    </GlobalConfigContext.Provider>
  );
};
