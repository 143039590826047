/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import { LabellingFieldId, LabellingFieldMap } from './global-config-data.types';
import { CountryDataType } from './locale-data.types';
import { StringIndexed } from './shared.types';

export const getCountryConfiguration = (
  country: string | undefined,
  countryConfiguration: StringIndexed<CountryDataType>
) => {
  return (country && countryConfiguration?.[country]) || undefined;
};

export const getCountryLabellingFieldMap = (
  country: string,
  countryConfiguration: StringIndexed<CountryDataType>
) => {
  return getCountryConfiguration(country, countryConfiguration)
    ?.labellingFields;
};

export const getCountryDatacenterUrl = (
  country: string,
  countryConfiguration: StringIndexed<CountryDataType>
) => {
  return getCountryConfiguration(country, countryConfiguration)?.datacenterUrl;
};

export const isLabellingFieldEnabled = (
  labellingFieldMap: LabellingFieldMap | undefined,
  labellingFieldId: LabellingFieldId
) => {
  return labellingFieldMap?.hasOwnProperty(labellingFieldId)
    ? labellingFieldMap[labellingFieldId].enabled
    : false;
};

export const getLabellingFieldValue = (
  labellingFieldMap: LabellingFieldMap | undefined,
  labellingFieldId: LabellingFieldId
) => {
  return labellingFieldMap?.hasOwnProperty(labellingFieldId)
    ? labellingFieldMap[labellingFieldId].value
    : undefined;
};

export const getCountryFromLocale = (locale: string | undefined) => {
  if (!locale || locale.trim() === '') {
    return undefined;
  }

  const localeItems = locale.split('-');
  return localeItems.length > 1
    ? localeItems[localeItems.length - 1]
    : undefined;
};

export const getLanguageFromLocale = (locale: string | undefined) => {
  if (!locale || locale.trim() === '') {
    return undefined;
  }

  const localeItems = locale.split('-');
  return localeItems[0];
};
