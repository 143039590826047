/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { mapI18nLocale } from '../../../domain/authentication/authentication.domain';
import { getCurrentDate } from '../../../domain/dates/dates.domain';
import { getCountryFromLocale, getLanguageFromLocale } from '../../../domain/global-config.domain';
import { i18nService } from '../../../services/i18n/i18n.service';
import { AppImage } from '../../images/images.constants';
import { getImageUrl } from '../../images/images.domain';
import { VdzAnchor } from '../../shared/components/anchor.component';
import { useGlobalConfig } from '../../shared/hooks/use-global-config/use-global-config.hook';
import { useLocale } from '../../shared/hooks/use-locale/use-locale.hook';
import {
  useTermsAcceptanceDate,
} from '../../shared/hooks/use-terms-acceptance-date/use-terms-acceptance-date.hook';
import { VdzLocaleConfirmation } from './locale-confirmation.component';
import { VdzLocaleSelector } from './locale-selector.component';

export const VdzLocalePicker = () => {
  const { t } = useTranslation();
  const { globalConfig } = useGlobalConfig();
  const { setLocale } = useLocale();
  const { setAcceptanceDate } = useTermsAcceptanceDate();

  const [selectedLocale, setSelectedLocale] = useState<string>();
  const [confirmed, setConfirmed] = useState<boolean>();

  const country = getCountryFromLocale(selectedLocale);
  const language = getLanguageFromLocale(selectedLocale);

  const selectCountryLocale = async (
    selectedCountry: string,
    selectedLocale: string
  ) => {
    const i18nCode = mapI18nLocale(selectedLocale, selectedCountry);
    setSelectedLocale(i18nCode);
    i18nService.changeLocale(i18nCode);
  };

  const onCancel = () => {
    setSelectedLocale(undefined);
  };

  const onConfirm = () => {
    setConfirmed(true);
    selectedLocale && setLocale(selectedLocale);
    setAcceptanceDate(getCurrentDate());
  };

  return (
    <section className="w-full h-full flex flex-col items-center">
      {country && language && !confirmed && (
        <VdzLocaleConfirmation
          country={country}
          language={language}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
      <div className="w-full h-full fixed flex justify-center items-center bg-entyvio-gradient overflow-y-auto z-30">
        <div className="w-full m-5 mb-24 xl:mt-5 lg:w-[926px] z-10">
          <div className="p-2 lg:p-5 rounded-lg shadow-lg bg-white">
            <img
              src={getImageUrl(AppImage.VdzLogo)}
              alt="vdz-logo"
              className="h-12 mx-auto my-8"
            />

            <h3 className="pb-2 text-xl font-bold text-black flex justify-center text-center">
              {t(`locale_picker.title`)}
            </h3>

            <p className="py-2 lg:py-5 text-lg font-light text-black flex justify-center text-center">
              <span className="lg:w-[472px]">{t(`locale_picker.subtext`)}</span>
            </p>

            <VdzLocaleSelector
              data={globalConfig.countryConfiguration}
              handleChange={selectCountryLocale}
              locale={selectedLocale}
            />

            <div className="font-light text-black text-center">
              <Trans
                i18nKey="locale_picker.country_not_listed_text"
                components={{
                  anchor: (
                    <VdzAnchor
                      href={`${t('locale_picker.country_not_listed_url')}`}
                    />
                  ),
                }}
                shouldUnescape={true}
              ></Trans>
            </div>
          </div>
        </div>

        {globalConfig.displayEntyvioLogo && (
          <img
            className="absolute
            bottom-6 xl:bottom-12
            right-6 xl:right-12
            h-12"
            alt={AppImage.EntyvioLogoWhite}
            src={getImageUrl(AppImage.EntyvioLogoWhite)}
          />
        )}
      </div>
    </section>
  );
};
