/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, {
  createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useState,
} from 'react';

import { EnvConfig } from '../../../../services/env-config/env-config.service';

type EnvConfigContextType = {
  envConfig: EnvConfig;
  setEnvConfig: Dispatch<SetStateAction<EnvConfig>>;
};

export const EnvConfigContext = createContext<EnvConfigContextType | undefined>(
  undefined
);

export const EnvConfigContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [envConfig, setEnvConfig] = useState<EnvConfig>({} as EnvConfig);

  return (
    <EnvConfigContext.Provider value={{ envConfig, setEnvConfig }}>
      {children}
    </EnvConfigContext.Provider>
  );
};
