/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

export const clearStorage = () => {
  return localStorage.clear();
};

export const getStorageValue = <T = string>(key: string): T | undefined => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : undefined;
};

export const setStorageValue = <T = string>(key: string, value: T): void => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorageKey = (key: string): void => {
  return localStorage.removeItem(key);
};
