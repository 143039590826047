/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import App from './App';
import { EnvConfigContextProvider } from './modules/shared/contexts/env-config/env-config.context';
import {
  GlobalConfigContextProvider,
} from './modules/shared/contexts/global-config/global-config.context';
import {
  LocalStorageContextProvider,
} from './modules/shared/contexts/local-storage/local-storage.context';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HashRouter>
    <GlobalConfigContextProvider>
      <EnvConfigContextProvider>
        <LocalStorageContextProvider>
          <App />
        </LocalStorageContextProvider>
      </EnvConfigContextProvider>
    </GlobalConfigContextProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
