/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

import { getImageUrl } from '../../images/images.domain';

export const VdzFooterLogo = () => {
  const { t } = useTranslation();
  const takedaLogo = t('takeda_logo');

  return (
    <div className="lg:w-[8rem] lg:w-[12rem] me-8">
      <a href="https://www.takeda.com" target="_blank" rel="noreferrer">
        <img
          className="h-7 lg:h-10"
          src={getImageUrl(takedaLogo)}
          alt="takeda-logo"
        />
      </a>
    </div>
  );
};
