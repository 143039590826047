/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

export enum LabellingImage {
  CE = 'ce-icon.png',
  CH_REP = 'ch-rep-icon.png',
  Distributor = 'distributor-icon.png',
  Importer = 'importer-icon.png',
  MD = 'md-icon.png',
  MFG = 'mfg-icon.png',
  REP = 'ec-rep-icon.png',
  UDI = 'udi-icon.png',
  UK_CE = 'ukca-icon.png',
  UK_REP = 'uk-rep-icon.png',
}

export enum IconImage {
  Alert = 'ic_alert.svg',
  CheckboxChecked = 'checkbox_checked.svg',
  CheckboxUnchecked = 'checkbox_unchecked.svg',
  ChevronLeft = 'chevron-left.svg',
  CloseWhite = 'close-white.svg',
  CountryPickerDownArrow = 'country-picker-down-arrow.svg',
  CountryPickerUpArrow = 'country-picker-up-arrow.svg',
  CountryPickerGlobe = 'country-picker-globe.svg',
  CountryPickerLanguage = 'country-picker-language.svg',
  Document = 'document-icon.svg',
  DownloadRed = 'download-red.svg',
  Globe = 'globe.svg',
  InfoQuestionMark = 'info-question-mark.svg',
  Information = 'information-icon.svg',
  Settings = 'ic_settings.svg',
}

export enum AppImage {
  DocCheckLogo = 'doc-check-logo.png',
  VdzLogo = 'vdz-logo.png',
  EntyvioLogoWhite = 'entyvio-logo-white.png',
}
