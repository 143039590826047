/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import axios from 'axios';

import { getBaseUrl } from '../../domain/browser.domain';

export const getEnvConfig = () => {
  return axios
    .get<EnvConfig>(`${getBaseUrl()}/config/env-config.json`)
    .then(({ data }) => data)
    .catch(() => {
      throw Error('Error fetching env data');
    });
};

export type EnvConfig = {
  oktaAccountsBaseUrl: string;
  oktaClientId: string;
  oktaIssuer: string;
};
