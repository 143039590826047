/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, {
  components,
  DropdownIndicatorProps,
  MenuListProps,
  StylesConfig,
} from 'react-select';

import { CountriesDataType } from '../../../domain/locale-data.types';
import { SelectOption } from '../../form/form.types';
import { IconImage } from '../../images/images.constants';
import { getImageUrl } from '../../images/images.domain';

type SelectorProps = {
  data: CountriesDataType;
  handleChange: (country: string, locale: string) => void;
  locale?: string;
};

export const VdzLocaleSelector: FC<SelectorProps> = ({
  data,
  handleChange,
  locale,
}) => {
  const [selectedCountry, setSelectedCountry] =
    useState<SelectOption<string> | null>();
  const [selectedLanguage, setSelectedLanguage] =
    useState<SelectOption<string> | null>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!locale) {
      setSelectedLanguage(null);
    }
  }, [locale]);

  const handleSelectedCountry = (country: SelectOption<string> | null) => {
    setSelectedCountry(country);
    setSelectedLanguage(null);
  };

  const handleSelectedLangauge = (language: SelectOption<string> | null) => {
    if (!language) return;

    setSelectedLanguage(language);
    handleChange(
      (selectedCountry?.value as string) || '',
      (language?.value as string) || ''
    );
  };

  const options = Object.keys(data)
    .map((country) => ({
      value: country,
      label: t(`locale_picker.countries.${country}`),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const languages = selectedCountry
    ? data[selectedCountry.value].locales.map((key) => {
        return {
          value: key,
          label: t(`locale_picker.locales.${key}`),
        };
      })
    : undefined;

  const CaretDownIcon = () => {
    return (
      <img
        src={getImageUrl(IconImage.CountryPickerDownArrow)}
        alt="country-picker-down-arrow"
      />
    );
  };

  const CaretUpIcon = () => {
    return (
      <img
        src={getImageUrl(IconImage.CountryPickerUpArrow)}
        alt="country-picker-up-arrow"
      />
    );
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    const { menuIsOpen } = props.selectProps;

    return (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? <CaretUpIcon /> : <CaretDownIcon />}
      </components.DropdownIndicator>
    );
  };

  const MenuList = (
    title: string,
    props: MenuListProps<SelectOption<string>>
  ) => {
    return (
      <components.MenuList {...props}>
        <div className="bg-[#f7f7f7] text-black font-bold text-sm p-2">{`${title}`}</div>
        <div className="text-sm">{props.children}</div>
      </components.MenuList>
    );
  };

  const dropDownStyles: StylesConfig = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#f7f7f7' : 'inherit',
      borderBottom: '1px solid #B7B7B7',
      color: 'black',
      '&:hover': {
        backgroundColor: '#f7f7f7',
      },
    }),
  };

  return (
    <div className="m-auto text-lg leading-relaxed text-slate-500 flex justify-center">
      {data && (
        <div>
          <div className="mx-4 flex items-center">
            <img
              className="md:me-2 h-6 w-6"
              src={getImageUrl(IconImage.CountryPickerGlobe)}
              alt="country-picker-globe"
            ></img>
            <label htmlFor="country" />
            <Select
              className="p-2 w-52 md:w-96 lg:w-[512px] rounded-md"
              classNames={{
                control: () => 'text-lg',
              }}
              name="country"
              id="country"
              placeholder={t('locale_picker.select_country')}
              onChange={(e: unknown) =>
                handleSelectedCountry((e as SelectOption<string>) || null)
              }
              value={selectedCountry}
              options={options}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
                MenuList: (props) =>
                  MenuList(
                    t('locale_picker.select_country'),
                    props as MenuListProps<SelectOption<string>>
                  ),
              }}
              styles={dropDownStyles}
            />
          </div>

          <div className="mx-4 mb-8 flex items-center">
            <img
              className="md:me-2 h-6 w-6"
              src={getImageUrl(IconImage.CountryPickerLanguage)}
              alt="country-picker-language"
            ></img>
            <label htmlFor="language" />
            <Select
              className="p-2 w-52 md:w-96 lg:w-[512px] rounded-md"
              classNames={{
                control: () => 'text-lg',
              }}
              name="language"
              id="language"
              placeholder={t('locale_picker.select_language')}
              isDisabled={!selectedCountry}
              onChange={(e: unknown) =>
                handleSelectedLangauge((e as SelectOption<string>) || null)
              }
              value={selectedLanguage}
              options={languages}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
                MenuList: (props) =>
                  MenuList(
                    t('locale_picker.select_language'),
                    props as MenuListProps<SelectOption<string>>
                  ),
              }}
              styles={dropDownStyles}
            />
          </div>
        </div>
      )}
    </div>
  );
};
