/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import { CustomUserClaim } from '@okta/okta-auth-js';

import {
  SESSION_EXPIRATION_TIMER_IN_MS,
} from '../../modules/authentication/authentication.constants';
import { getPathAfterHash } from '../browser.domain';
import { StorageKeys } from '../storage/storage.constants';
import { getStorageValue } from '../storage/storage.domain';

const getPaq = () => {
  return (window._paq = window._paq || []);
};

const getMtm = () => {
  return (window._mtm = window._mtm || []);
};

export const setTrackedUserId = (
  userId: CustomUserClaim | CustomUserClaim[] | undefined
) => {
  const _paq = getPaq();
  console.log(`Setting tracked userId: ${userId}`);
  _paq.push(['setUserId', userId]);
};

export const setTrackedTakedaId = (
  takeda_id: CustomUserClaim | CustomUserClaim[] | undefined
) => {
  const _mtm = getMtm();
  console.log(`Setting tracked takeda_id: ${takeda_id}`);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _mtm.push({ takeda_id } as any);
};

export const setTrackedDocumentTitle = () => {
  const _paq = getPaq();
  const title = document.title;
  console.log(`Setting tracked document title: ${title}`);
  _paq.push(['setDocumentTitle', title]);
};

export const setTrackedCustomUrl = () => {
  const _paq = getPaq();
  const customUrl = getPathAfterHash() || '/';
  console.log(`Setting tracked document url: ${customUrl}`);
  _paq.push(['setCustomUrl', customUrl]);
};

export const setTrackedCountry = (country: string) => {
  const _mtm = getMtm();
  console.log(`Setting tracked country: ${country}`);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _mtm.push({ country } as any);
};

export const getSessionDate = (): Date => {
  const currentTimestamp = getStorageValue(StorageKeys.SessionTimestamp);
  const currentExpiration = getStorageValue(StorageKeys.SessionExpiration);
  if (!(currentTimestamp && currentExpiration)) return new Date();

  const currentExpirationDate = new Date(currentExpiration);
  return currentExpirationDate > new Date()
    ? new Date(currentTimestamp)
    : new Date();
};

export const getSessionTimestamp = (): string => {
  const sessionExpiration = getStorageValue(StorageKeys.SessionTimestamp);
  if (!sessionExpiration) {
    throw new Error('Session Expiration has not been saved yet.');
  }
  return sessionExpiration;
};

export const getRenewedSessionExpiration = () => {
  return new Date(Date.now() + SESSION_EXPIRATION_TIMER_IN_MS);
};
