/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  CALCULATOR_PAGE, PRESCRIBING_INFORMATION_PAGE, RESOURCES_PAGE,
} from '../../navigation/navigation.constants';
import { VdzHeaderLink } from './header-link.component';

export const VdzHeaderLinks = () => {
  const { t } = useTranslation();
  const withExternalPI = t('footer.vdz_indication_and_pi.embedded') !== 'true';

  return (
    <div className="flex flex-col sm:flex-row sm:gap-6 md:gap-12 justify-between items-center">
      <VdzHeaderLink href={CALCULATOR_PAGE} title="footer.calculator.title" />
      <VdzHeaderLink href={RESOURCES_PAGE} title="footer.resources.title" />
      <VdzHeaderLink
        isExternal={withExternalPI}
        href={
          withExternalPI
            ? 'footer.vdz_indication_and_pi.href'
            : PRESCRIBING_INFORMATION_PAGE
        }
        title="footer.vdz_indication_and_pi.title"
      />
    </div>
  );
};
