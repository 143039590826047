/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import { useCookies } from 'react-cookie';

import { getCookieOptions } from '../../../../domain/cookies/cookies.domain';
import { dateToString, toDate } from '../../../../domain/dates/dates.domain';
import { TERMS_ACCEPTANCE_DATE_COOKIE } from '../../../authentication/authentication.constants';

export const useTermsAcceptanceDate = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    TERMS_ACCEPTANCE_DATE_COOKIE,
  ]);

  const acceptanceDate = toDate(cookies[TERMS_ACCEPTANCE_DATE_COOKIE]);

  const setAcceptanceDate = (acceptanceDate: Date) => {
    return setCookie(
      TERMS_ACCEPTANCE_DATE_COOKIE,
      dateToString(acceptanceDate),
      getCookieOptions()
    );
  };

  const clearAcceptanceDate = () => {
    removeCookie(TERMS_ACCEPTANCE_DATE_COOKIE, getCookieOptions());
  };

  return {
    acceptanceDate,
    clearAcceptanceDate,
    setAcceptanceDate,
  };
};
