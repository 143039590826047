/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { FC, lazy } from 'react';

import {
  useDocumentTitle,
} from '../modules/shared/hooks/use-document-title/use-document-title.hook';
import { VdzPage } from './page';

const VdzPrescribingInformation = lazy(
  () =>
    import(
      /*webpackChunkName: "prescribing-information" */ '../modules/prescribing-information/components/prescribing-information.component'
    )
);

export const PrescribingInformationPage: FC = () => {
  useDocumentTitle('footer.vdz_indication_and_pi.title');

  return (
    <VdzPage>
      <VdzPrescribingInformation />
    </VdzPage>
  );
};
