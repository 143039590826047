/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { getLanguageFromLocale } from '../../domain/global-config.domain';
import { baseline, MESSAGE_NAMESPACE } from './i18n.constants';

const init = () => {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: baseline,
      returnNull: false,
      backend: {
        loadPath: `/language/${MESSAGE_NAMESPACE}_{{lng}}.json`,
      },
    });
};

const changeLocale = (locale: string) => {
  i18n.changeLanguage(locale);
  document.documentElement.lang = getLanguageFromLocale(locale) || baseline;
  window.OneTrust?.changeLanguage();
};

export const i18nService = {
  changeLocale,
  init,
  i18n,
};
