/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import i18next from 'i18next';

export const keyHasValue = (key: string) => {
  return i18next.exists(key) && i18next.t(key) !== '';
};
