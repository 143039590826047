/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import './login-selection.page.scss';

import React, { FC, lazy } from 'react';

import { VdzPage } from '../page';

const VdzLoginSelection = lazy(
  () =>
    import(
      /*webpackChunkName: "login-selection" */ '../../modules/authentication/login/login-selection.component'
    )
);

export const LoginSelectionPage: FC = () => (
  <VdzPage name="login-selection" className="bg-brand-gray-light">
    <VdzLoginSelection />
  </VdzPage>
);
