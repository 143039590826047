/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

import { keyHasValue } from '../../../domain/i18n/i18n.domain';
import { getImageUrl } from '../../images/images.domain';

export const VdzFooterCountryLogo = () => {
  const { t } = useTranslation();

  const withImage = keyHasValue('footer.country_logo.img');
  const countryLogoImg = t('footer.country_logo.img');
  const countryLogoHref = keyHasValue('footer.country_logo.href')
    ? t('footer.country_logo.href')
    : '#';

  return withImage ? (
    <a href={countryLogoHref} target="_blank" rel="noreferrer">
      <img
        className="h-7 lg:h-10"
        src={getImageUrl(countryLogoImg)}
        alt="country-logo"
      />
    </a>
  ) : (
    <></>
  );
};
