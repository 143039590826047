/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { createContext, FC, PropsWithChildren, useState } from 'react';

import {
  clearStorage, getStorageValue, removeStorageKey, setStorageValue,
} from '../../../../domain/storage/storage.domain';

type LocalStorageContextType = {
  clearStorage: () => void;
  getStorageValue: <T extends string>(key: string) => T | undefined;
  setStorageValue: <T extends string>(key: string, value: T) => void;
  removeStorageKey: (key: string) => void;
};

export const LocalStorageContext = createContext<
  LocalStorageContextType | undefined
>(undefined);

export const LocalStorageContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [_, setStorageVersion] = useState<number>(0);

  const incrementStorageVersion = () =>
    setStorageVersion((storageVersion) => {
      return storageVersion + 1;
    });

  const _clearStorage = () => {
    incrementStorageVersion();
    return clearStorage();
  };

  const _setStorageValue = <T extends string>(key: string, value: T): void => {
    incrementStorageVersion();
    return setStorageValue(key, value);
  };

  const _removeStorageKey = (key: string): void => {
    incrementStorageVersion();
    return removeStorageKey(key);
  };

  return (
    <LocalStorageContext.Provider
      value={{
        clearStorage: _clearStorage,
        getStorageValue,
        setStorageValue: _setStorageValue,
        removeStorageKey: _removeStorageKey,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};
