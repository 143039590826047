/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const replaceUrl = (url: string) => {
  window.history.replaceState(null, '', url);
};

export const getBaseUrl = () => {
  return window.location.protocol + '//' + window.location.host;
};

export const getMainDomain = () => {
  return window.location.hostname.split('.').slice(-2).join('.');
};

export const getPathAfterHash = () => {
  return window.location.hash.slice(1).trim();
};
