/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { FC, PropsWithChildren, useState } from 'react';

import { IconImage } from '../../images/images.constants';
import { getImageUrl } from '../../images/images.domain';
import { WithClassName } from '../shared.types';
import { VdzHTML } from './html.component';

type Props = React.InputHTMLAttributes<HTMLInputElement> &
  PropsWithChildren<{
    checked?: boolean;
    text?: string;
  }>;

export const VdzCheckbox: FC<WithClassName<Props>> = ({
  children,
  className,
  name,
  checked = false,
  text = '',
  onChange,
}) => {
  const [isChecked, setChecked] = useState(checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setChecked(checked);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <label
      className={`flex items-start cursor-pointer ${
        className ? className : ''
      }`}
    >
      <input
        className="opacity-0 h-0 w-0"
        name={name}
        type="checkbox"
        aria-checked={isChecked}
        aria-label={text}
        checked={isChecked}
        onChange={handleChange}
      />
      {isChecked ? (
        <img
          className="inline h-6"
          src={getImageUrl(IconImage.CheckboxChecked)}
          alt="Checked"
        />
      ) : (
        <img
          className="inline h-6"
          src={getImageUrl(IconImage.CheckboxUnchecked)}
          alt="Unchecked"
        />
      )}
      &nbsp;
      <div className="font-medium text-start">
        {children || <VdzHTML html={text} />}
      </div>
    </label>
  );
};
