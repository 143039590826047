/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { isAuthPage } from '../../../domain/authentication/authentication.domain';
import { getImageUrl } from '../../images/images.domain';
import { VdzHamburgerComponent } from './hamburger.component';
import { VdzHeaderLinks } from './header-links.component';

export const VdzHeader = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [headerHidden, setHeaderHidden] = useState<boolean>(true);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setHeaderHidden(isAuthPage(pathname));
    if (expanded) toggleExpanded();
  }, [pathname]);

  const toggleExpanded = () => setExpanded(!expanded);

  const takedaLogo = t('takeda_logo');

  return headerHidden ? null : (
    <header
      data-testid="app-header"
      className="w-full bg-entyvio-gradient text-white rounded-b-6xl mb-8 print:hidden"
    >
      <div className="flex flex-row justify-between py-5 px-7 sm:px-11">
        <a href="https://www.takeda.com" target="_blank" rel="noreferrer">
          <img
            src={getImageUrl(takedaLogo)}
            alt="takeda-logo"
            className="w-24 sm:w-36"
          />
        </a>

        <div className="flex sm:hidden items-center">
          <VdzHamburgerComponent expanded={expanded} onClick={toggleExpanded} />
        </div>

        <div className="hidden sm:flex">
          <VdzHeaderLinks />
        </div>
      </div>

      <div
        className={`
          flex sm:hidden justify-center overflow-hidden transition-all duration-300
          ${expanded ? 'h-32' : 'h-0'}
        `}
      >
        <div className="pb-4">
          <VdzHeaderLinks />
        </div>
      </div>
    </header>
  );
};
