/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import { TFunction } from 'i18next';
import { ParsedQuery } from 'query-string';

import OktaAuth, { IDToken } from '@okta/okta-auth-js';

import {
  getDocCheckAccessToken, validateDocCheckAccessToken,
} from '../../modules/authentication/authentication.api';
import { getImageUrl } from '../../modules/images/images.domain';
import {
  CALCULATOR_PAGE, LOGIN_PAGE, SIGN_UP_PAGE, TAKEDA_ID_LOGIN_PAGE,
} from '../../modules/navigation/navigation.constants';
import { EnvConfig } from '../../services/env-config/env-config.service';
import { replaceUrl } from '../browser.domain';
import { getCountryConfiguration } from '../global-config.domain';
import { CountriesDataType } from '../locale-data.types';
import { StringIndexed } from '../shared.types';
import {
  CustomWidgetOptions, DocCheckErrorType, ErrorTranslations, LoginType, OidcConfig,
  TakedaIdErrorType,
} from './authentication.types';

export const getOktaUrl = (
  envConfig: EnvConfig,
  locale: string,
  path: string
): string => {
  return `${envConfig.oktaAccountsBaseUrl}${locale}/${path}`;
};

export const getOidcConfig = (envConfig: EnvConfig): OidcConfig => ({
  clientId: envConfig.oktaClientId,
  issuer: envConfig.oktaIssuer,
  redirectUri: `${window.location.origin}/`,
  scopes: ['openid', 'profile', 'email'],
});

export const getSupportedLocales = (countries: CountriesDataType) => {
  const supportedLocales = Object.keys(countries).reduce(
    (acc, country) => [
      ...acc,
      ...countries[country].locales.map((locale) =>
        mapI18nLocale(locale, country)
      ),
    ],
    [] as string[]
  );
  return supportedLocales;
};

export const mapI18nLocale = (language: string, country: string) => {
  return `${language}-${country}`;
};

export const getWidgetConfig = (
  envConfig: EnvConfig,
  countryConfiguration: CountriesDataType,
  selectedLocale: string,
  t: TFunction
): CustomWidgetOptions => {
  const { issuer, clientId, redirectUri, scopes } = getOidcConfig(envConfig);
  return {
    baseUrl: issuer.split('/oauth2')[0],
    clientId,
    redirectUri,
    logo: getImageUrl('takeda-logo.svg'),
    i18n: {
      [selectedLocale]: {
        'oie.primaryauth.submit': t('authentication.login.sign_in'),
        'oie.remember': t('authentication.login.remember_me'),
        'primaryauth.password.placeholder': t('authentication.login.password'),
        'primaryauth.title': t('authentication.login.sign_in'),
        'primaryauth.username.placeholder': t('authentication.login.username'),
        forgotpassword: t('authentication.login.forgot_password'),
        help: t('authentication.login.help'),
        needhelp: t('authentication.login.need_help'),
        goback: t('authentication.login.back'),
        'model.validation.field.blank': t(
          'authentication.login.error.field_cannot_be_empty'
        ),
        'oform.errorbanner.title': t('authentication.login.error.generic'),
        'errors.E0000004': t('authentication.login.error.unable_to_sign_in'),
        'idx.session.expired': t('authentication.session_expired'),
      },
    },
    language: selectedLocale,
    assets: {
      languages: getSupportedLocales(countryConfiguration),
    },
    authParams: {
      issuer,
      scopes,
    },
    helpLinks: {
      forgotPassword: getOktaUrl(envConfig, selectedLocale, 'forgot-password'),
      help: getOktaUrl(envConfig, selectedLocale, 'faq'),
      custom: [
        {
          text: t('authentication.login.register'),
          href: getOktaUrl(envConfig, selectedLocale, 'signUp'),
          target: '_blank',
        },
      ],
    },
    features: {
      showPasswordToggleOnSignInPage: true,
      rememberMe: false,
      showKeepMeSignedIn: false,
    },
  };
};

export const replaceTakedaLoginUrl = () => {
  replaceUrl(`/#${TAKEDA_ID_LOGIN_PAGE}`);
};

export const getLoginTypesForCountry = (
  country: string,
  countryConfiguration: CountriesDataType
) => {
  const config = getCountryConfiguration(country, countryConfiguration);
  if (!config) return undefined;

  let loginTypes = config.authMethods?.map(({ id }) => id as LoginType);
  if (!loginTypes?.length) {
    loginTypes = [LoginType.Unauthenticated];
  }
  return loginTypes;
};

export const getClientIdForCountryAndLoginType = (
  country: string,
  countryConfiguration: CountriesDataType,
  loginType: LoginType
) => {
  const authMethods = getCountryConfiguration(
    country,
    countryConfiguration
  )?.authMethods;

  return authMethods?.find(({ id }) => (id as LoginType) === loginType)
    ?.clientId;
};

export const validateDocCheckLogin = async (
  country: string,
  countryConfiguration: CountriesDataType,
  authenticationCode?: string,
  accessToken?: string
) => {
  const clientId = getClientIdForCountryAndLoginType(
    country as string,
    countryConfiguration,
    LoginType.DocCheck
  );

  if (accessToken) {
    const { boolIsValid } = await validateDocCheckAccessToken(accessToken);

    return boolIsValid ? accessToken : undefined;
  } else if (authenticationCode && country && clientId) {
    const { access_token } = await getDocCheckAccessToken(
      authenticationCode,
      country,
      clientId
    );

    return access_token;
  } else {
    return undefined;
  }
};

export const getAuthRequiredNavigateTo = (oktaAuth?: OktaAuth) => {
  const previousAuthState = oktaAuth?.authStateManager.getPreviousAuthState();
  return !previousAuthState || !previousAuthState.isAuthenticated
    ? LOGIN_PAGE
    : CALCULATOR_PAGE;
};

export const isDocCheckLoginType = (
  searchParams: ParsedQuery<string>,
  loginTypes?: LoginType[],
  docCheckAccessToken?: string
) => {
  return !!(
    loginTypes?.includes(LoginType.DocCheck) &&
    (searchParams.dc_timestamp ||
      docCheckAccessToken ||
      loginTypes?.length === 1)
  );
};

export const getAuthenticationErrorKeys = (
  loginType: LoginType
): StringIndexed<ErrorTranslations> => {
  return loginType === LoginType.TakedaId
    ? {
        [TakedaIdErrorType.InvalidCountry]: {
          contact: 'contact_us.technical_support.email',
          reason: 'authentication.country_error.reason',
        },
        [TakedaIdErrorType.WrongCountry]: {
          reason: 'authentication.wrong_country_error.reason',
        },
        [TakedaIdErrorType.InvalidUser]: {
          reason: 'authentication.invalid_user.reason',
        },
      }
    : {
        [DocCheckErrorType.RequestError]: {
          reason: 'authentication.login.error.unable_to_sign_in',
        },
      };
};

export const isLoginTypeSupported = (
  loginType: LoginType,
  country: string,
  countryConfiguration: CountriesDataType
) => {
  return getLoginTypesForCountry(country, countryConfiguration)?.includes(
    loginType
  );
};

export const isAuthPage = (pathname: string) => {
  return pathname.includes(LOGIN_PAGE) || pathname.includes(SIGN_UP_PAGE);
};

export const getUserId = (idToken: IDToken | undefined) => {
  return idToken?.claims.customer_id;
};

export const getTakedaId = (idToken: IDToken | undefined) => {
  return idToken?.claims.digital_id;
};
