/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

const imagesPath = '/media/img';

export const getImageUrl = (image: string) => {
  return `${imagesPath}/${image}`;
};
