/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  isExternal?: boolean;
  href: string;
  title: string;
};

export const VdzFooterLink: FC<Props> = ({
  isExternal = false,
  href,
  title,
}) => {
  const { t } = useTranslation();
  const link = isExternal ? t(href) : href;

  return link ? (
    <div className="flex justify-center items-center">
      {isExternal ? (
        <a href={link} className={className} target="_blank" rel="noreferrer">
          {t(title)}
        </a>
      ) : (
        <Link to={link} className={className}>
          {t(title)}
        </Link>
      )}
    </div>
  ) : null;
};

const className = `font-bold text-sm text-center`;
