/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React, { FC, lazy } from 'react';

import {
  useDocumentTitle,
} from '../modules/shared/hooks/use-document-title/use-document-title.hook';
import { VdzPage } from './page';

const VdzContactUsAbout = lazy(
  () =>
    import(
      /*webpackChunkName: "contact-us-about" */ '../modules/contact-us-about/components/contact-us-about.component'
    )
);

export const ContactUsAboutPage: FC = () => {
  useDocumentTitle('footer.contact_us.title');

  return (
    <VdzPage>
      <VdzContactUsAbout />
    </VdzPage>
  );
};
