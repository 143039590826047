/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import React from 'react';

import { keyHasValue } from '../../../domain/i18n/i18n.domain';
import { CONTACT_US_PAGE, COOKIE_POLICY_PAGE } from '../../navigation/navigation.constants';
import { VdzFooterLink } from './footer-link.component';

export const VdzFooterLinks = () => {
  const withAdditionalLink = keyHasValue('footer.additional_link.title');

  return (
    <div className="flex flex-col md:flex-row gap-1 md:gap-6 lg:gap-12 justify-center text-neutral-600">
      <VdzFooterLink
        isExternal={true}
        href="footer.terms_of_use.href"
        title="footer.terms_of_use.title"
      />
      <VdzFooterLink
        isExternal={true}
        href="footer.privacy_notice.href"
        title="footer.privacy_notice.title"
      />
      <VdzFooterLink href={CONTACT_US_PAGE} title="footer.contact_us.title" />
      <VdzFooterLink
        href={COOKIE_POLICY_PAGE}
        title="footer.cookie_policy.title"
      />
      {withAdditionalLink && (
        <VdzFooterLink
          isExternal={true}
          href="footer.additional_link.href"
          title="footer.additional_link.title"
        />
      )}
    </div>
  );
};
