/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import { getMainDomain } from '../browser.domain';

export const getCookieOptions = () => {
  return { path: '/', domain: `.${getMainDomain()}` };
};
