/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type Props = {
  isExternal?: boolean;
  href: string;
  title: string;
};

export const VdzHeaderLink: FC<Props> = ({
  isExternal = false,
  href,
  title,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const link = isExternal ? t(href) : href;

  const selected = pathname === link;

  return link ? (
    <div className={`flex flex-col relative ${selected ? 'font-bold' : ''}`}>
      {isExternal ? (
        <a href={link} className={className} target="_blank" rel="noreferrer">
          {t(title)}
        </a>
      ) : (
        <Link to={link} className={className}>
          {t(title)}
        </Link>
      )}

      {selected ? (
        <div
          className="
            absolute
            mb-1 sm:mb-0
            bottom-0
            sm:-bottom-2
            xl:-bottom-4
            w-full
            border-b-4 sm:border-b-6 3xl:border-b-8
            border-white
            rounded"
        ></div>
      ) : null}
    </div>
  ) : null;
};

const className = `flex items-center min-h-9 sm:min-h-0 text-md xl:text-xl text-center`;
