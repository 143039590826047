/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Environment } from '../../../domain/global-config-data.types';
import { useGlobalConfig } from '../../shared/hooks/use-global-config/use-global-config.hook';
import { pagesWithoutBanner } from '../banner.constants';

export const VdzDemoBanner: FC = () => {
  const { globalConfig } = useGlobalConfig();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isVisible = () => {
    return (
      globalConfig.environmentName === Environment.DEMO &&
      !pagesWithoutBanner.includes(pathname)
    );
  };

  return isVisible() ? (
    <div
      className="
        demo-banner bg-brand-red text-white text-2xl mt-2
        text-center p-4 print:text-xl print:mt-0 print:mb-4"
    >
      {t('demo.banner.content')}
    </div>
  ) : null;
};
