/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import {
  DOC_CHECK_LOGIN_PAGE, LOGIN_PAGE, SIGN_UP_PAGE, TAKEDA_ID_LOGIN_PAGE,
} from '../navigation/navigation.constants';

export const pagesWithoutBanner = [
  DOC_CHECK_LOGIN_PAGE,
  LOGIN_PAGE,
  SIGN_UP_PAGE,
  TAKEDA_ID_LOGIN_PAGE,
];
