/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useDocumentTitle = (title: string) => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    document.title = t(title);
  }, [i18n.language, title]);
};
