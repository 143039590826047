/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import './page.scss';

import React, { FC, PropsWithChildren, Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import { isAuthPage } from '../domain/authentication/authentication.domain';
import { VdzFooter } from '../modules/footer/components/footer.component';
import { VdzSpinner } from '../modules/shared/components/spinner.component';

type Props = PropsWithChildren<{
  className?: string;
  name?: string;
}>;

export const VdzPage: FC<Props> = ({ children, className, name }) => {
  const { pathname } = useLocation();
  const isAuth = isAuthPage(pathname);

  return (
    <Suspense fallback={<VdzSpinner />}>
      <div
        className={`${isAuth ? 'auth' : ''} page ${name || ''} ${
          className || ''
        } w-full`}
      >
        <div className="page-contents w-full">{children}</div>
        <VdzFooter isAuthPage={isAuth} />
      </div>
    </Suspense>
  );
};
